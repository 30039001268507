import { type Session } from "next-auth/core/types"
import { type GtpPermission } from "shared-utils"
import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

type TUsePermissionStore = {
  storedPermissions: GtpPermission[]
  setStoredPermissions: (data: GtpPermission[]) => void
  hasPermissions: (permissions: GtpPermission | GtpPermission[]) => boolean
}

const initialStored: GtpPermission[] = []

export const usePermissionStore = create<TUsePermissionStore>()(
  devtools(
    persist(
      (set, get) => ({
        storedPermissions: initialStored,
        setStoredPermissions: (data = []) =>
          set(() => ({
            storedPermissions: data,
          })),
        hasPermissions: (permissions) => {
          if (Array.isArray(permissions)) {
            return (
              permissions.length === 0 ||
              get().storedPermissions.some((p) => permissions.includes(p))
            )
          } else {
            return !permissions || get().storedPermissions.includes(permissions)
          }
        },
      }),
      {
        name: "permissions-storage",
      }
    )
  )
)

export function hasPermissions(
  sessionUser: Session | null,
  permissions: GtpPermission[] = []
) {
  if (!sessionUser) return false
  const gtpPermissions = sessionUser.user?.permissions || []
  return (
    permissions.length === 0 ||
    gtpPermissions.some((p) => permissions.includes(p))
  )
}
