import { useCategoryStore } from "@/store/categoryStore"
import useSessionStorageCleanup from "@/utils/useSessionStorageCleanup"

const WHITELIST_PATH = [
  "category/add",
  "category/edit",
  "category/kbli",
  "category/kbki",
]

const useDeleteCategoryStorage = () => {
  const { storedCategory, clearStoredCategory } = useCategoryStore()

  const cleanupCategoryStorage = () => {
    if (!!Object.keys(storedCategory).length) {
      clearStoredCategory()
    }
  }

  useSessionStorageCleanup(cleanupCategoryStorage, WHITELIST_PATH)
}

export default useDeleteCategoryStorage
