import { useMasterProductStore } from "@/store/masterProductStore"
import useSessionStorageCleanup from "@/utils/useSessionStorageCleanup"

const WHITELIST_PATH = ["product"]

const useDeleteMasterProductStore = () => {
  const { storedProduct, clearStoredProduct } = useMasterProductStore()

  const cleanupMasterProductStorage = () => {
    if (!!Object.keys(storedProduct).length) {
      clearStoredProduct()
    }
  }

  useSessionStorageCleanup(cleanupMasterProductStorage, WHITELIST_PATH)
}

export default useDeleteMasterProductStore
