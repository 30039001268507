import { Box, Link, Text } from "@theme-ui/components"
import type { MouseEvent } from "react"
import { useEffect } from "react"
import { X } from "react-feather"

import AlertStyle from "./styles"
import { useAlertStore } from "@/utils/useAlert"

/*
    TODO Add Dynamic Positioning
    TODO Add More Color Variation
    TODO Run Custom Function After Close
    TODO Add More Animation Variation
    TODO Add Custom Hide Time
    TODO Prevent Duplicate
    TODO Custom Component
*/

const AlertOTP = () => {
  const { message, variant, hasClose, closeAlert } = useAlertStore(
    (state) => state
  )
  const isOpen = Boolean(message)

  const classes = AlertStyle()

  const getSx = () => {
    const getVariant = () => {
      switch (variant) {
        case "error": {
          return classes.colorError
        }
        case "success": {
          return classes.colorSuccess
        }
        default: {
          return classes.colorDefault
        }
      }
    }

    const color = getVariant()

    if (isOpen) {
      return {
        ...color,
        ...classes.boxMain,
        ...classes.boxOpen,
      }
    }

    return {
      ...color,
      ...classes.boxMain,
      ...classes.boxClose,
    }
  }

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        closeAlert()
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  const onClose = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    closeAlert()
  }

  if (!isOpen) {
    return null
  }

  return (
    <Box sx={getSx()}>
      <Text sx={classes.textMessage}>{message}</Text>
      {hasClose && (
        <Link onClick={onClose} sx={classes.colorGrey}>
          <X />
        </Link>
      )}
    </Box>
  )
}

export default AlertOTP
