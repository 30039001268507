import { type Session } from "next-auth"
import { SessionProvider as Provider } from "next-auth/react"
import { useEffect } from "react"
import { clearSession } from "shared-utils/authentication"

import DialogAuth from "./DialogAuth"
import useDeleteCategoryStorage from "@/app/(sidebar-layout)/category/hooks/useDeleteCategoryStorage"
import useDeleteMasterProductStore from "@/app/(sidebar-layout)/category/product/hooks/useDeleteMasterProductStore"
import { usePermissionStore } from "@/store/permissionStore"

type TSessionProvider = {
  session: Session | null
  children: React.ReactNode
}

export const SessionProvider = ({ session, children }: TSessionProvider) => {
  const { setStoredPermissions } = usePermissionStore()
  useDeleteCategoryStorage()
  useDeleteMasterProductStore()
  clearSession()

  // Update session
  useEffect(() => {
    if (session?.user?.permissions) {
      setStoredPermissions(session.user.permissions)
    }
    // even when the effect run twice, GTM will dedup the result
    window.dataLayer?.push({
      user_id: session?.user?.id ?? "",
      persona_id: session?.user?.personaId ?? "",
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  return (
    <Provider session={session}>
      <DialogAuth session={session} />
      {children}
    </Provider>
  )
}

export default SessionProvider
