import type { PropsWithChildren } from "react"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

import Alert from "@/components/alert-otp"

type AlertTypes = {
  message: string
  variant: "error" | "success"
  hasClose: boolean
}

interface AlertStoreTypes {
  message: string
  variant: "error" | "success"
  hasClose: boolean
  setAlert: ({ message, variant, hasClose }: AlertTypes) => void
  closeAlert: () => void
}

export const useAlertStore = create<AlertStoreTypes>()(
  devtools(
    (set) => ({
      message: "",
      variant: "success",
      hasClose: false,
      setAlert: ({ message, variant, hasClose }) =>
        set(() => ({
          message,
          variant,
          hasClose,
        })),
      closeAlert: () => set(() => ({ message: "", variant: "success" })),
    }),
    {
      name: "alert-store",
    }
  )
)

const AlertProvider = (props: PropsWithChildren) => {
  return (
    <>
      <Alert />
      {props.children}
    </>
  )
}

export default AlertProvider
