import { create } from "zustand"
import { createJSONStorage, devtools, persist } from "zustand/middleware"

import { type TCategoryFormSchema } from "@/app/(sidebar-layout)/category/form/schema"

type TStoredCategory = Partial<TCategoryFormSchema>

type TUseCategoryStore = {
  storedCategory: TStoredCategory
  updateStoredCategory: (updatedFields: Partial<TStoredCategory>) => void
  clearStoredCategory: () => void
}

const defaultStoredCategory = {}

export const useCategoryStore = create<TUseCategoryStore>()(
  devtools(
    persist(
      (set, get) => ({
        storedCategory: defaultStoredCategory,
        updateStoredCategory: (updatedFields) =>
          set(() => ({
            storedCategory: {
              ...get().storedCategory,
              ...updatedFields,
            },
          })),
        clearStoredCategory: () =>
          set({
            storedCategory: defaultStoredCategory,
          }),
      }),
      {
        name: "categrory-storage-v2",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
)
