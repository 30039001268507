import { usePathname } from "next/navigation"
import { useEffect } from "react"

const isWhitelisted = (pathname: string, whitelistPath: string[]) => {
  for (let index = 0; index < whitelistPath.length; index++) {
    const dtPath = whitelistPath[index]
    if (dtPath && pathname.includes(dtPath)) {
      return true
    }
  }
  return false
}

const useSessionStorageCleanup = (
  callback: () => void,
  whitelistPath: string[]
) => {
  const pathname = usePathname()
  useEffect(() => {
    if (isWhitelisted(pathname || "", whitelistPath)) {
      return
    }
    callback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
}

export default useSessionStorageCleanup
